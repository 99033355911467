import { __decorate } from "tslib";
import { Component, Prop, Watch, Ref, Emit, } from 'vue-property-decorator';
import { OperateType, UnitType, } from '../../../typings/organization/org-manage';
import * as Utils from '@/common/utils';
import UserFormBase from './user-form/user-form-base';
import { OrgManageService } from '@/sevices';
let AddUser = class AddUser extends UserFormBase {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.saveLoading = false;
        this.saveContinuAddLoading = false;
        this.OperateType = OperateType;
    }
    onValueChange(v) {
        this.visible = v;
        if (v) {
            this.generateSingleOrgInfo();
            this.form.baseUserInfo.sex = 0;
        }
    }
    generateSingleOrgInfo() {
        const orgInfo = {
            orgInfo: {
                id: this.deptDetail.orgId,
                name: this.deptDetail.organizationName,
                type: UnitType.Dept,
            },
            mainDepart: [{
                    id: this.deptDetail.id,
                    name: this.deptDetail.name,
                    type: UnitType.Dept,
                }],
            mainDepartPath: this.deptDetail.namePath,
            mainDepartSuperior: [],
        };
        this.form.orgInfoList = super.generateOrgInfo([orgInfo]);
    }
    onSave() {
        const isValid = this.userForm.cform.validate();
        if (!isValid)
            return;
        this.saveLoading = true;
        super.submit(OrgManageService.createUser).then((res) => {
            if (res.success) {
                this.$message.success('添加成功');
                this.onClose();
                this.reload();
            }
        }).finally(() => {
            this.saveLoading = false;
        });
    }
    onSaveContinuAdd() {
        const isValid = this.userForm.cform.validate();
        if (!isValid)
            return;
        this.saveContinuAddLoading = true;
        super.submit(OrgManageService.createUser).then(async (res) => {
            if (res.success) {
                this.$message.success('添加成功');
                this.onClose();
                this.reload();
                await Utils.sleep(400);
                this.$parent.addUser();
            }
        }).finally(() => {
            this.saveContinuAddLoading = false;
        });
    }
    onClose() {
        return false;
    }
    reload() { }
    ;
};
__decorate([
    Ref()
], AddUser.prototype, "userForm", void 0);
__decorate([
    Prop()
], AddUser.prototype, "value", void 0);
__decorate([
    Prop({ default: () => { } })
], AddUser.prototype, "deptDetail", void 0);
__decorate([
    Ref()
], AddUser.prototype, "modal", void 0);
__decorate([
    Watch('value', { immediate: true })
], AddUser.prototype, "onValueChange", null);
__decorate([
    Emit('input')
], AddUser.prototype, "onClose", null);
__decorate([
    Emit('reload')
], AddUser.prototype, "reload", null);
AddUser = __decorate([
    Component({
        name: 'addUser',
        components: {
            UserForm: () => import('./user-form/index.vue'),
        },
    })
], AddUser);
export default AddUser;
