import { Vue } from 'vue-property-decorator';
import { UnitType, } from '../../../../typings/organization/org-manage';
import moment from 'moment';
export default class UserFormBase extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            baseUserInfo: {
                imgUrl: '',
                name: '',
                account: '',
                mobile: '',
                sex: 0,
            },
            orgInfoList: [],
        };
        this.rules = {
            baseUserInfo: {
                name: [
                    { required: true, message: '用户名不能为空', trigger: 'blur' },
                    {
                        validator: (value) => /^[\u4e00-\u9fa5_a-zA-Z0-9\-()]{1,20}$/.test(value),
                        message: '支持中英文、数字、英文符号“-”、“_”和“()”，最大长度20个字',
                        trigger: 'blur',
                    },
                ],
                account: [
                    { required: true, message: '账号不能为空', trigger: 'blur' },
                    {
                        validator: (value) => /^[\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{1,50}$/.test(value),
                        message: '支持英文、数字和英文字符，最大长度50个字',
                        trigger: 'blur',
                    },
                ],
                mobile: [
                    { required: true, message: '手机号码不能为空', trigger: 'blur' },
                    { type: 'isPhone', message: '请输入正确的手机号码！', trigger: 'blur' },
                ],
            },
        };
    }
    /**
     * 设置主部门和兼职部门禁止选中项
     */
    setStaffSelectorDisabledKeys() {
        this.form.orgInfoList.forEach((orgInfo) => {
            // 当前兼职部门不能选择主部门和其它兼职部门
            orgInfo.partTimeDeparts.forEach((partTimeDepartItem) => {
                const disabledPartTimeDepart = orgInfo.partTimeDeparts.filter((p) => {
                    return p.partTimeDepart[0]?.id !== partTimeDepartItem.partTimeDepart[0]?.id;
                });
                const partTimeDepartDisabledKeys = [
                    ...(disabledPartTimeDepart.map((disabledItem) => disabledItem.partTimeDepart[0]?.id)),
                ];
                if (orgInfo.mainDepart[0]?.id) {
                    partTimeDepartDisabledKeys.push(orgInfo.mainDepart[0]?.id);
                }
                partTimeDepartItem.partTimeDepartDisabledKeys = partTimeDepartDisabledKeys;
            });
            // 主部门不能选择兼职部门
            const mainDepartDisabledKeys = orgInfo.partTimeDeparts.map((partTimeDepartItem) => {
                return partTimeDepartItem.partTimeDepart[0]?.id;
            });
            orgInfo.mainDepartDisabledKeys = mainDepartDisabledKeys.filter(key => !!key);
        });
    }
    /**
     * 初始化组织信息
     * @returns
     */
    getOrgInfo() {
        return {
            orgInfo: {
                id: '',
                name: '',
                type: UnitType.Dept,
            },
            mainDepart: [],
            mainDepartPath: '',
            mainDepartSuperior: [],
            position: '',
            entryDate: '',
            employeeNo: '',
            officePhone: '',
            email: '',
        };
    }
    generateOrgInfo(orgInfoLists) {
        const baseOrgInfo = this.getOrgInfo();
        const result = [];
        orgInfoLists.forEach((item) => {
            const orgInfo = {
                ...baseOrgInfo,
                ...item,
            };
            result.push(orgInfo);
        });
        return result;
    }
    getSumitParams() {
        const baseUserInfo = this.form.baseUserInfo;
        const orgInfoList = this.form.orgInfoList[0];
        const orgInfo = orgInfoList?.orgInfo;
        const mainDepartSuperior = orgInfoList?.mainDepartSuperior[0];
        const mainDepartment = orgInfoList?.mainDepart[0];
        const entryDate = orgInfoList.entryDate && moment(orgInfoList.entryDate).format('YYYY-MM-DD');
        const params = {
            imgUrl: baseUserInfo.imgUrl,
            name: baseUserInfo.name,
            username: baseUserInfo.account,
            mobile: baseUserInfo.mobile,
            gender: baseUserInfo.sex,
            orgId: orgInfo?.id,
            mainDepartmentId: mainDepartment?.id,
            parentUserId: mainDepartSuperior?.id,
            employeeNo: orgInfoList.employeeNo,
            entryDate: entryDate,
            officePhone: orgInfoList.officePhone,
            email: orgInfoList.email,
            position: orgInfoList.position,
        };
        // 编辑需要带上用户id
        if (baseUserInfo.id) {
            params.id = baseUserInfo.id;
        }
        // 兼职部门
        const partTimeDepartments = [];
        const partTimeDeparts = this.form.orgInfoList[0].partTimeDeparts || [];
        for (const item of partTimeDeparts) {
            const partTimeDepart = item.partTimeDepart[0];
            const partTimeDepartSuperior = item.partTimeDepartSuperior[0];
            if (partTimeDepart?.id) {
                partTimeDepartments.push({
                    parentUserId: partTimeDepartSuperior?.id,
                    departmentId: partTimeDepart?.id,
                });
            }
        }
        if (partTimeDepartments.length) {
            params.partTimeDepartments = partTimeDepartments;
        }
        return params;
    }
    submit(submit) {
        const params = this.getSumitParams();
        return submit(params);
    }
}
